
















import Vue from 'vue';
import AppHeader from '@/components/app/navigation/Header.component.vue';
import AppFooter from '@/components/shared/footer/Footer.component.vue';

export default Vue.extend({

  name: 'Layout',

  components: {
    AppHeader,
    AppFooter
  },

  data () {
    return {
      darkMode: true
    };
  },

  created (): void {
    this.setTheme();
  },

  methods: {
    onToggleDarkMode (): void {
      this.darkMode = !this.darkMode;
      this.$vuetify.theme.dark = this.darkMode;
      localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    },

    setTheme (): void {
      const theme = localStorage.getItem('theme');
      this.darkMode = theme === 'dark';
      this.$vuetify.theme.dark = this.darkMode;
    }
  }

});
