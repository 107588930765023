










































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({

  name: 'Header',

  data () {
    return {
      darkMode: true
    };
  },

  computed: {
    // ...mapGetters('auth', {
    //   user: 'user',
    //   pendingAuthentication: 'pendingAuthentication'
    // })

    ...mapGetters('network', {
      appIsOffline: 'isOffline'
    })
  },

  created () {
    this.setTheme();
  },

  methods: {
    onToggleDarkMode () {
      this.darkMode = !this.darkMode;
      this.$vuetify.theme.dark = this.darkMode;
      localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    },

    setTheme () {
      const theme = localStorage.getItem('theme');
      this.darkMode = theme === 'dark';
      this.$vuetify.theme.dark = this.darkMode;
    },

    onGoTo (routeName: string): void {
      this.$router.push({ name: routeName });
    },

    onLogout (): void {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'Landing' });
    }
  }

});
